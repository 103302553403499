import Vue from "vue";

/*TITLE*/
document.title = "Seadreams | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Seadreams";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Seadreams";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "opc3_salon_urban-acero-nat_rgb--20230125030144.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "opc-6_banyo-principal_urbanlimit-acero-nat_rgb--20230125030115.jpg";
Vue.prototype.$image_bath2 = "opc-6_banyo-secundario_urbanlimit-acero-nat_rgb--20230125040105.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "opc3_salon_urban-acero-nat_rgb--20230125030144.jpg",
  },
  {
    src: "opc2_salon_bottega-caliza_rgb--20230125030153.jpg",
  },
  {
    src: "opc1_salon_baltimore-beige_rgb--20230125030104.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "opc-6_banyo-principal_urbanlimit-acero-nat_rgb--20230125030115.jpg",
  },
  {
    src: "opc-5_banyo-principal_baltimore-beigechinaoxo-deco-blanco_rgb--20230125040128.jpg",
  },
  {
    src: "opc-4_banyo-principal_bottega-calizamadagascarona-blanco_rgb--20230125040138.jpg",
  },
  {
    src: "opc-3_banyo-principal_baltimore-beige_rodanomosaico-caliza_rgb--20230125040145.jpg",
  },
  {
    src: "opc-2_banyo-principal_urban-acero-natnewportold-beige_rgb--20230125040154.jpg",
  },
  {
    src: "opc-1_banyo-principal_bottegaspiga-calizamarbella-stone_rgb--20230125040158.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "opc-6_banyo-secundario_urbanlimit-acero-nat_rgb--20230125040105.jpg",
  },
  {
    src: "opc-5_banyo-secundario_baltimore-beigechinaoxo-deco-blanco_rgb--20230125040116.jpg",
  },
  {
    src: "opc-4_banyo-secundario_bottega-calizamadagascarona-blanco_rgb--20230125040127.jpg",
  },
  {
    src: "opc-3_banyo-secundario_baltimore-beoge_rodanomosaico-caliza_rgb--20230125040142.jpg",
  },
  {
    src: "opc-2_banyo-secundario_urban-acero-natnewportold-beige_rgb--20230125040156.jpg",
  },
  {
    src: "opc-1_secundario_bottegaspiga-calizamarbella-stone_rgb--20230125040113.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=murcia",
    text: "Murcia",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=murcia",
    text: "Murcia",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:608785489",
    text: "608785489",
  },
];
